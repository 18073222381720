import { Text } from "@lrnz/styled";
import styled from "styled-components";
import { hover } from "@lrnz/styled";

const StyledLink = styled(Text)`
  cursor: pointer;

  &::after {
    content: "";
    display: block;
    height: 2px;
    width: 30px;
    margin: auto;
    background-color: currentColor;
    transform: scale(0);
    transition: all 0.3s ease;
  }

  ${hover`
    &::after {
      transform: scale(1);
    }
  `}

  ${(props) =>
    props.$isActive &&
    `
    cursor: default;
    &::after {
      transform: scale(1);
    }
  `}
`;

const LinkComponent = (props) => {
  return <StyledLink variant="interface" {...props} />;
};

export default LinkComponent;
