import React, { useEffect } from "react";
import WrapperGallery from "../components/WrapperGallery";
import Gallery from "../components/Gallery";
import { useCreations, useDataContext, useInterviews } from "../context";
import { useLocation } from "react-router";
import RevealElement from "../components/RevealElement";
import useThemeBreakpoint from "../hooks/useThemeBreakPoint";

const GalleryContainer = (props) => {
  const location = useLocation();
  const { images } = useDataContext();
  const creations = useCreations();
  const interview = useInterviews();
  const { setFromHomepage, setGalleryType, fromHomepage } = useDataContext();

  const { filter } = props.match.params;

  const items =
    filter === "interview"
      ? interview
      : filter === "creations"
      ? creations
      : images;

  const initialIndex = location.state
    ? items.findIndex((item) => item.src === location.state.src)
    : undefined;

  const isMd = useThemeBreakpoint("md");

  useEffect(() => {
    setGalleryType(filter);
  }, [setGalleryType, filter]);

  useEffect(() => {
    if (filter && !isMd) {
      document.documentElement.style.setProperty("--gallery-padding", "0px");
    } else {
      document.documentElement.style.removeProperty("--gallery-padding");
    }

    return () => {
      document.documentElement.style.removeProperty("--gallery-padding");
    };
  }, [filter, isMd]);

  useEffect(() => {
    return () => {
      setFromHomepage(false);
      setGalleryType(undefined);
    };
  }, [setGalleryType, setFromHomepage]);

  return (
    <RevealElement
      enter={!fromHomepage ? "clipY" : undefined}
      exit="clipYReverse"
    >
      <WrapperGallery>
        <Gallery
          items={items}
          initialIndex={initialIndex}
          centerNext={filter}
        />
      </WrapperGallery>
    </RevealElement>
  );
};

export default GalleryContainer;
