import React, {
  forwardRef,
  useImperativeHandle,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import Scrollbar from "react-smooth-scrollbar";
import useThemeBreakpoint from "../hooks/useThemeBreakPoint";
import { Row, Col } from "@lrnz/styled";
import CircleIcon from "../components/CircleIcon";
import { motion, useMotionValue, useSpring } from "framer-motion";
import RevealElement from "./RevealElement";

const StyledCol = styled.div`
  position: relative;
`;

const StyledCircleIcon = styled.div`
  position: absolute;
  bottom: ${(props) => props.theme.space.md}px;
  left: 0px;
`;

const DoubleScrollbar = forwardRef(({ children, reverse }, ref) => {
  const [items, setItems] = useState([]);
  const [isClicked, setIsClicked] = useState(false);
  const isMd = useThemeBreakpoint("md");
  const scrollbar1Ref = useRef();
  const scrollbar2Ref = useRef();

  const springConfig = {
    damping: 35,
    stiffness: 300,
  };
  const translateX = useMotionValue(0);
  const rotateZ = useMotionValue(0);
  const translateXSpring = useSpring(translateX, springConfig);
  const rotateZSpring = useSpring(rotateZ, springConfig);

  const getPassiveScrollbar = useCallback(
    () => items.find(({ passive }) => passive)?.ref.current.scrollbar,
    [items]
  );
  const getActiveScrollbar = useCallback(
    () => items.find(({ passive }) => !passive)?.ref.current.scrollbar,
    [items]
  );

  const resetScrollbars = useCallback(() => {
    getPassiveScrollbar()?.setPosition(0, getPassiveScrollbar().limit.y);
    getActiveScrollbar()?.setPosition(0, 0);
  }, [getPassiveScrollbar, getActiveScrollbar]);

  useEffect(() => {
    const activeScrollbar =
      items.filter((item) => item.ref.current).length === 2;

    if (activeScrollbar) {
      const passiveScrollbar = getPassiveScrollbar();
      const activeScrollbar = getActiveScrollbar();

      const activeListener =
        (scrollbar) =>
        ({ limit, offset }) => {
          const percentage = (offset.y / limit.y) * 100;
          const position = (percentage * scrollbar.limit.y) / 100;

          scrollbar.setPosition(0, scrollbar.limit.y - position, true);
        };

      resetScrollbars();

      activeScrollbar.addListener(activeListener(passiveScrollbar));
      passiveScrollbar.addListener(activeListener(activeScrollbar));
    }
  }, [items, resetScrollbars, getPassiveScrollbar, getActiveScrollbar]);

  useEffect(() => {
    setItems([
      {
        ref: scrollbar1Ref,
        passive: false,
      },
      {
        ref: scrollbar2Ref,
        passive: true,
      },
    ]);
  }, []);

  useImperativeHandle(ref, () => ({ resetScrollbars }));

  useEffect(() => {
    translateX.set(isClicked ? window.innerWidth * -0.5 : 0);
    rotateZ.set(isClicked ? -180 : 0);
  }, [isClicked, translateX, rotateZ]);

  const onCircleIconClick = () => setIsClicked(!isClicked);

  return (
    <motion.div
      style={{
        width: isMd && "150vw",
        translateX: translateXSpring,
      }}
    >
      <Row reverse={reverse}>
        <Col col={14} sm={{ col: 12 }}>
          <Scrollbar ref={scrollbar1Ref}>{children[0]}</Scrollbar>
        </Col>
        <Col as={StyledCol}>
          <Scrollbar ref={scrollbar2Ref}>{children[1]}</Scrollbar>
          {isMd && (
            <StyledCircleIcon
              as={CircleIcon}
              forwardedAs={RevealElement}
              onClick={onCircleIconClick}
              enter="scale"
              exit="scale"
              delay={2}
              style={{
                translateX: "-50%",
              }}
            >
              <motion.svg
                width="6.953"
                height="12.494"
                viewBox="0 0 6.953 12.494"
                style={{
                  rotateZ: rotateZSpring,
                }}
              >
                <path
                  d="M-18280.248,24071.916l5.893,5.893-5.893,5.895"
                  transform="translate(18280.602 -24071.563)"
                />
              </motion.svg>
            </StyledCircleIcon>
          )}
        </Col>
      </Row>
    </motion.div>
  );
});

export default DoubleScrollbar;
