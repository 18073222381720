import { useEffect, useCallback, useRef } from 'react';
import { EVENTS } from './constants';
import useResize from './use-resize';
import { angleBetweenPoints, distanceBetweenPoints, modulate } from '@lrnz/dev-utils/math';

import { normalizeEvent } from './utils';

const useMouse = (ref, cb, props = []) => {
  const handler = useCallback(cb, [cb, ...props]);

  const halfScreenRef = useRef({
    x: global.innerWidth / 2,
    y: global.innerHeight / 2,
  })

  const onResize = useCallback(() => {
    halfScreenRef.current.x = global.innerWidth / 2
    halfScreenRef.current.y = global.innerHeight / 2
  }, [])

  useEffect(() => {
    const p1 = halfScreenRef.current;

    const p2 = {
      x: global.innerWidth / 2,
      y: global.innerHeight / 2,
    };

    const onMove = (e) => {
      const x = normalizeEvent(e).clientX;
      const y = normalizeEvent(e).clientY;

      p2.x = x;
      p2.y = y;

      const distance = modulate(
        distanceBetweenPoints([p1.x, p1.y], [p2.x, p2.y]),
        [0, Math.sqrt(p1.x*p1.x + p1.y*p1.y)], // map to viewport diagonal
        [0, 1],
      )

      const angle = angleBetweenPoints([p1.x, p1.y], [p2.x, p2.y])

      handler(angle, distance);
    };

    const onLeave = () => {
      handler(0, 0);
    }

    const $el = ref ? ref.current : global;

    $el.addEventListener('blur', onLeave);
    $el.addEventListener(EVENTS.LEAVE, onLeave);
    $el.addEventListener(EVENTS.MOVE, onMove);

    return () => {
      if ($el) {
        $el.removeEventListener('blur', onLeave);
        $el.removeEventListener(EVENTS.LEAVE, onLeave);
        $el.removeEventListener(EVENTS.MOVE, onMove);
      }
    };
  }, [handler, ref]);

  useResize(ref, onResize, [onResize]);
};

export default useMouse;