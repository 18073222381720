import { hover } from "@lrnz/styled";
import { forwardRef } from "react";
import styled from "styled-components";

const StyledImage = styled.img`
  display: block;
  max-width: 100%;
  max-height: 100%;
  aspect-ratio: ${(props) => props.ratio};
  width: ${(props) => (props.fullWidth ? "100%" : null)};

  ${(props) =>
    props.saturateOnHover &&
    `
    transition: filter 0.4s ease;
    filter: saturate(0%);
  `}
  ${(props) =>
    props.saturateOnHover &&
    hover`
    filter: saturate(100%);
  `}
`;

const Image = forwardRef(
  (
    {
      src,
      ratio,
      params = `?w=${window.innerWidth < 768 ? 500 : 1200}`,
      ...props
    },
    ref
  ) => (
    <StyledImage
      ref={ref}
      draggable={false}
      src={`${process.env.REACT_APP_IMGIX_PATH}/${src}${params}`}
      ratio={ratio}
      {...props}
    />
  )
);

export default Image;
