/* eslint-disable import/no-anonymous-default-export */
import { useEffect, useCallback, useRef } from 'react';
import { EVENTS } from './constants';
import { normalizeEvent } from './utils';
import { angleBetweenPoints } from '@lrnz/dev-utils/math';

export default (ref, cb, props = []) => {
  const handler = useCallback(cb, [cb, ...props]);
  const p1 = useRef({})

  useEffect(() => {
    const p2 = {};

    const onStart = (e) => {
      p1.current.x = normalizeEvent(e).clientX;
      p1.current.y = normalizeEvent(e).clientY;
    };

    const onEnd = (e) => {
      const x = normalizeEvent(e).clientX;
      const y = normalizeEvent(e).clientY;

      if (Math.abs(x - p1.current.x) > 3 || Math.abs(y - p1.current.y) > 3) {
        p2.x = x;
        p2.y = y;
        handler(angleBetweenPoints([p1.current.x, p1.current.y], [p2.x, p2.y]));
      }
    };

    const $el = ref ? ref.current : global;

    $el.addEventListener(EVENTS.START, onStart);
    $el.addEventListener(EVENTS.END, onEnd);

    return () => {
      $el.removeEventListener(EVENTS.START, onStart);
      $el.removeEventListener(EVENTS.END, onEnd);
    };
  }, [handler, ref]);
};
