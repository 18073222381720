import React from "react";
import { motion } from "framer-motion";
import RevealElement, { presets } from "./RevealElement";

const RevealStagger = ({
  children,
  delay = 0,
  stagger = 0.1,
  duration,
  as = "div",
  ...props
}) => {
  const items =
    typeof children === "string"
      ? children.split("")
      : React.Children.toArray(children);

  const Component = motion[as];

  return (
    <Component
      initial="initial"
      animate="animate"
      transition={{
        delayChildren: delay,
        staggerChildren: stagger,
      }}
      {...props}
    >
      {items.map((el, index) => (
        <RevealElement
          variants={presets.letter}
          key={el.key || el + index}
          animate={false}
          exit={null}
          initial={null}
          duration={duration}
        >
          {el}
        </RevealElement>
      ))}
    </Component>
  );
};

export default RevealStagger;
