import { useEffect, useCallback } from 'react';

const useResize = (ref, cb, props = [], debounce = 100) => {
  const handler = useCallback(cb, [cb, ...props]);

  useEffect(() => {
    const $el = ref.current;
    let timeout;

    const onResize = () => {
      clearTimeout(timeout);
      timeout = setTimeout(handler, debounce);
    };

    handler();

    const resizeObserver = new global.ResizeObserver(onResize);
    resizeObserver.observe($el);

    return () => {
      clearTimeout(timeout);
      resizeObserver.unobserve($el);
    };
  }, [debounce, handler, ref]);
};

export default useResize;