import { useState, useEffect } from "react";
import styled from "styled-components";
import { AnimatePresence, motion } from "framer-motion";
import { nextIndex } from "@lrnz/dev-utils/array/index-direction";
import { useDataContext } from "../context";
import { Text } from "@lrnz/styled";
import useThemeBreakpoint from "../hooks/useThemeBreakPoint";
import Image from "../components/Image";
import RevealElement from "../components/RevealElement";
import { useSwipeable } from "react-swipeable";

const StyledWrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  text-align: center;
  position: relative;
  cursor: pointer;
  color: black;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  touch-action: none;
`;

const StyledText = styled(Text)`
  position: fixed;
  bottom: ${(props) => props.theme.space.sm}px;
`;

const StyledSlide = styled(motion.div)`
  display: flex;
  justify-content: center;
  position: absolute;
  inset: 0;
  filter: saturate(var(--saturation));

  > img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
`;

const transition = { duration: 0.5, ease: [0.64, 0.02, 0.14, 0.99] };

const Gallery = ({ items, centerNext, initialIndex = 0 }) => {
  const { setCursorText } = useDataContext();
  const [index, setIndex] = useState(0);
  const [isActive, setIsActive] = useState(true);

  const isMd = useThemeBreakpoint("md");

  const active = () => setIsActive(true);
  const disactive = () => setIsActive(false);

  const next = nextIndex(index, items.length);
  const goToNext = () => setIndex(nextIndex(index, items.length));

  useEffect(() => {
    setCursorText(`${index + 1} — ${items.length}`);
  }, [index, items, initialIndex, setCursorText]);

  useEffect(() => {
    return () => {
      setCursorText(null);
    };
  }, [setCursorText]);

  const handlers = useSwipeable({
    onSwipedDown: goToNext,
  });

  const orderedItems = [
    items.find((item, index) => index === initialIndex),
    ...items.filter((item, index) => index !== initialIndex),
  ];

  return (
    <StyledWrapper {...handlers} onClick={isActive ? goToNext : null}>
      <AnimatePresence>
        <StyledSlide
          key={next}
          transition={transition}
          animate={{
            opacity: 0.5,
            y: !centerNext ? "-18%" : 0,
            scale: isMd ? 0.7 : 0.8,
            "--saturation": "0%",
          }}
          initial={{
            opacity: 0,
            y: !centerNext ? "-10%" : 0,
            scale: 0.5,
            "--saturation": "0%",
          }}
          exit={{
            opacity: 1,
            scale: 1,
            y: 0,
            zIndex: 1,
            "--saturation": "100%",
          }}
        >
          <Image src={orderedItems[next].src} />
        </StyledSlide>
      </AnimatePresence>
      <AnimatePresence initial={false}>
        <StyledSlide
          key={index}
          onAnimationStart={disactive}
          onAnimationComplete={active}
          animate={{
            opacity: 1,
            transition: {
              delay: transition.duration - 0.05,
              duration: 0,
            },
          }}
          initial={{ opacity: 0 }}
          transition={transition}
          exit={{
            opacity: 0,
            zIndex: 1,
            y: !isMd ? 100 : 0,
          }}
        >
          <Image src={orderedItems[index].src} />
        </StyledSlide>
      </AnimatePresence>
      {isMd && (
        <StyledText variant="caption" as={RevealElement} enter="fade">
          {index + 1} — {items.length}
        </StyledText>
      )}
    </StyledWrapper>
  );
};

export default Gallery;
