/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-webpack-loader-syntax */
import { createGlobalStyle } from "styled-components";
import { breakpoints } from "@lrnz/styled";

const fontFaceWoff2 = ({ name, src }) => `
  @font-face {
    font-family: '${name}';
    src: url(${src}) format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
`;

export default createGlobalStyle`
  ${fontFaceWoff2({
    name: "acidgrotesk-medium",
    src: require("!url-loader!../fonts/acidgrotesk-medium.woff2").default,
  })}
  ${fontFaceWoff2({
    name: "ayer-medium",
    src: require("!url-loader!../fonts/ayer-medium.woff2").default,
  })}
  ${fontFaceWoff2({
    name: "ayer-mediumitalic",
    src: require("!url-loader!../fonts/ayer-mediumitalic.woff2").default,
  })}
  ${fontFaceWoff2({
    name: "grifinitol-lightitalic",
    src: require("!url-loader!../fonts/grifinitol-lightitalic.woff2").default,
  })}
  ${fontFaceWoff2({
    name: "grifinitol-regular",
    src: require("!url-loader!../fonts/grifinitol-regular.woff2").default,
  })}

  :root {
    --vh: 1vh;
    --gallery-padding: 120px;
    --gallery-item-padding: clamp(20px, 6vw, 80px);
    --gallery-item-padding-small: clamp(40px, 8vw, 120px);
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    user-select: none;
  }

  i {
    font-style: normal;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  html {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  body {
    ${(props) => props.theme.typography.paragraph};
    background: black;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: normal;
  }

  [data-scrollbar] {
    height: calc(var(--vh) * 100);
  }

  .scrollbar-track {
    display: none!important;
  }

  ${breakpoints("md")(
    (props) => `
    :root {
      --gallery-padding: 80px ${props.theme.space.sm}px ${props.theme.space.lg}px ${props.theme.space.sm}px;
    }
  `
  )}

  ${breakpoints("sm")(
    (props) => `
    .remove-br {
      br {
        display: inline;
        content: ' ';
        &:after {
          content: ' ';
        }
      }
    }
  `
  )}
`;
