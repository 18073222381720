import React, { useEffect } from 'react';
import styled from 'styled-components';
import {
  motion, useMotionValue, useSpring, useTransform,
} from 'framer-motion';
import { Text } from '@lrnz/styled';
import { supportsHover } from '@lrnz/styled';
import { useDataContext } from '../context';

const StyledCursor = styled(motion.div)`
  ${supportsHover`
    position: fixed;
    top: ${(props) => props.radius * -1}px;
    left: ${(props) => props.radius * -1}px;
    width: ${(props) => props.radius * 2}px;
    height: ${(props) => props.radius * 2}px;
    border-radius: ${(props) => props.radius}px;
    border: ${(props) => (props.transparent ? `1px solid ${props.color}` : null)};
    background-color: ${(props) => (!props.transparent ? props.color : null)};
    z-index: ${props => props.theme.zIndex.cursor};
    pointer-events: none;
    mix-blend-mode: difference;
    white-space: nowrap;
  `}
`;

const StyledText = styled(motion.div)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`

const Cursor = () => {
  const { cursorText } = useDataContext();
  const scaleMotionValue = useMotionValue(1);
  const cursorX = useMotionValue(-100);
  const cursorY = useMotionValue(-100);

  const springConfig = { damping: 35, stiffness: 300 };
  const springX = useSpring(cursorX, springConfig);
  const springY = useSpring(cursorY, springConfig);
  const springScale = useSpring(scaleMotionValue, springConfig);

  const scale = useTransform([
    springX,
    springY,
    springScale,
  ],
  () => {
    const xVel = springX.getVelocity();
    const yVel = springY.getVelocity();

    return cursorText ? springScale.current : springScale.current + Math.abs(xVel + yVel) * 0.0002;
  });

  useEffect(() => {
    const target = global.document.documentElement;

    const onMouseMove = (e) => {
      cursorX.set(e.clientX);
      cursorY.set(e.clientY);
    };

    const onMouseEnter = (e) => {
      if (e.target.href) {
        scaleMotionValue.set(cursorText ? 0 : 1.8);
      }
    };

    const onMouseLeave = (e) => {
      if (e.target.href) {
        scaleMotionValue.set(1);
      }
    };

    target.addEventListener('mouseenter', onMouseEnter, { capture: true });
    target.addEventListener('mouseleave', onMouseLeave, { capture: true });
    global.addEventListener('mousemove', onMouseMove);

    return () => {
      target.removeEventListener('mouseenter', onMouseEnter, { capture: true });
      target.removeEventListener('mouseleave', onMouseLeave, { capture: true });
      global.removeEventListener('mousemove', onMouseMove);
    };
  }, [cursorX, cursorY, cursorText, scaleMotionValue]);

  useEffect(() => {
    scaleMotionValue.set(1);
  }, [scaleMotionValue, cursorText])

  return (
    <StyledCursor
      color="currentColor"
      radius={cursorText ? 0 : 5}
      style={{
        scale,
        translateX: springX,
        translateY: springY,
      }}
    >
      {cursorText && <StyledText as={Text} variant="caption">{cursorText}</StyledText>}
    </StyledCursor>
  );
};

export default Cursor;