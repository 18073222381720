import {
  useState, useEffect, useCallback, useRef,
} from 'react';
import { EVENTS } from './constants';
import { normalizeEvent } from './utils';

const useDrag = (ref, cb, props = [], {
  preventDefault = {
    x: false,
    y: false,
  },
  threshold = 14,
  onDragLeft = () => { },
  onDragRight = () => { },
} = {}) => {
  const [isActive, setIsActive] = useState();
  const isPressedRef = useRef(false);

  const value = useRef({
    x: 0,
    y: 0,
  });

  const delta = useRef({
    x: 0,
    y: 0,
  });

  const prev = useRef({
    x: 0,
    y: 0,
  });

  const handler = useCallback(cb, [cb, ...props]);

  const onStart = useCallback(
    (e) => {
      const x = normalizeEvent(e).clientX;
      const y = normalizeEvent(e).clientY;

      prev.current.x = x;
      prev.current.y = y;

      value.current.x = x;
      value.current.y = y;

      isPressedRef.current = true;
    }, [],
  );

  const onMove = useCallback(
    (e) => {
      if (!isPressedRef.current) return;

      const x = normalizeEvent(e).clientX;
      const y = normalizeEvent(e).clientY;

      delta.current.x = prev.current.x - value.current.x;
      delta.current.y = prev.current.y - value.current.y;

      prev.current.x = value.current.x;
      prev.current.y = value.current.y;

      value.current.x = x;
      value.current.y = y;

      if (e.cancelable) {
        const preventX = (
          Math.abs(delta.current.x) > threshold
          && (preventDefault.x || preventDefault === true)
        );
        const preventY = (
          Math.abs(delta.current.y) > threshold
          && (preventDefault.y || preventDefault === true)
        );
        if (preventX || preventY) {
          e.preventDefault();
        }
      }

      setIsActive(true);

      handler(delta.current, prev.current);
    },
    [handler, preventDefault, threshold],
  );

  const onEnd = useCallback(() => {
    if (delta.current.x > threshold) {
      onDragRight();
    } else if (delta.current.x < -threshold) {
      onDragLeft();
    }

    setIsActive(false);

    isPressedRef.current = false;
    delta.current.x = 0;
    delta.current.y = 0;
  }, [onDragLeft, onDragRight, threshold]);

  useEffect(() => {
    const $el = ref ? ref.current : global;
    $el.addEventListener(EVENTS.START, onStart, { passive: !preventDefault });
    $el.addEventListener(EVENTS.MOVE, onMove, { passive: !preventDefault });
    $el.addEventListener(EVENTS.END, onEnd, { passive: !preventDefault });
    $el.addEventListener(EVENTS.LEAVE, onEnd, { passive: !preventDefault });

    return () => {
      $el.removeEventListener(EVENTS.START, onStart, { passive: !preventDefault });
      $el.removeEventListener(EVENTS.MOVE, onMove, { passive: !preventDefault });
      $el.removeEventListener(EVENTS.END, onEnd, { passive: !preventDefault });
      $el.removeEventListener(EVENTS.LEAVE, onEnd, { passive: !preventDefault });
    };
  }, [onStart, onMove, onEnd, ref, preventDefault]);

  return isActive;
};

export default useDrag;