import { Suspense, useEffect, useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { ThemeProvider } from "styled-components";
import { Grid } from "@lrnz/styled";
import Handlers from "@lrnz/react-handlers";
import Contacts from "./Contacts";
import Homepage from "./Homepage";
import Gallery from "./Gallery";
import Detail from "./Detail";
import { Provider } from "../context";
import GlobalStyles from "../styles/Global";
import theme from "../styles/theme";

import AnimatedSwitch from "../components/AnimatedSwitch";
import Loader from "../components/Loader";
import Interface from "../components/Interface";
import Cursor from "../components/Cursor";

const App = () => {
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowLoader(false);
    }, 6000);
  }, []);

  return (
    <Handlers preferChangeOrientation debounce={0}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Suspense fallback={null}>
          <Provider>
            <Grid masked>
              <Cursor />
              <BrowserRouter>
                <AnimatePresence>
                  {showLoader && <Loader key={0} />}
                  {!showLoader && (
                    <>
                      <AnimatedSwitch>
                        <Route path="/" exact component={Homepage} />
                        <Route
                          path="/:template(creations|interview)"
                          component={Detail}
                        />
                        <Route
                          path="/gallery/:filter(creations|interview)?"
                          component={Gallery}
                        />
                        <Route path="/contacts" component={Contacts} />
                      </AnimatedSwitch>
                      <Interface />
                    </>
                  )}
                </AnimatePresence>
              </BrowserRouter>
            </Grid>
          </Provider>
        </Suspense>
      </ThemeProvider>
    </Handlers>
  );
};

export default App;
