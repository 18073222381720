import styled from "styled-components";
import { motion, useMotionValue, useTransform, useSpring } from "framer-motion";
import { supportsTouch } from "@lrnz/styled";

const StyledElement = styled(motion.div)`
  position: absolute;
  pointer-events: none;
  z-index: -1;
  ${supportsTouch`
    display: none;
  `}
`;

const StyledContent = styled.div`
  transform: translateX(-50%) translateY(-50%);
`;

const StyledWrapper = styled.div`
  display: inline-flex;
  z-index: 0;
  position: relative;
`;

const HoverElement = ({ element, children }) => {
  const x = useMotionValue(0);
  const y = useMotionValue(0);
  const opacity = useMotionValue(0);

  const springConfig = { damping: 50, stiffness: 300 };
  const springX = useSpring(x, springConfig);
  const springY = useSpring(y, springConfig);
  const springOpacity = useSpring(opacity, springConfig);

  const onMouseEnter = () => {
    opacity.set(1);
  };

  const onMouseMove = (e) => {
    x.set(e.pageX - e.currentTarget.offsetLeft);
    y.set(e.nativeEvent.offsetY);
  };

  const onMouseLeave = () => {
    opacity.set(0);
  };

  const rotateZ = useTransform([springX], () => {
    const xVel = springX.getVelocity();
    return xVel * 0.003;
  });

  return (
    <StyledWrapper
      onMouseEnter={onMouseEnter}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
    >
      <StyledElement
        style={{
          x: springX,
          y: springY,
          opacity: springOpacity,
          rotateZ,
        }}
      >
        <StyledContent>{element}</StyledContent>
      </StyledElement>
      {children}
    </StyledWrapper>
  );
};

export default HoverElement;
