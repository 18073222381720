import styled from "styled-components";
import { Text, hover, breakpoints } from "@lrnz/styled";
import useThemeBreakpoint from "../hooks/useThemeBreakPoint";

const StyledLabel = styled(Text)`
  display: inline-flex;
  align-items: center;
  min-width: 18em;
  margin-right: 2em;

  span {
    display: inline-block;
    transition: 0.3s all ease;
    transform: translateX(-20px);
    opacity: 0;
  }

  &:before {
    display: inline-flex;
    content: "●";
    transition: 0.3s all ease;
    margin-right: 0.5em;
    font-size: 3em;
  }

  ${breakpoints("sm")`
    min-width: 0px;
  `}
`;

const StyledWrapper = styled.div`
  ${hover`
    ${StyledLabel} {
      span {
        transform: translateX(0);
        opacity: 1;
      }
      &:before {
        transform: scale(1.7);
      }
    }
  `}
`;

const DotText = ({ label, children }) => {
  const isMd = useThemeBreakpoint("md");

  return (
    <StyledWrapper>
      <StyledLabel variant="small">{!isMd && <span>{label}</span>}</StyledLabel>
      <span dangerouslySetInnerHTML={{ __html: children }} />
    </StyledWrapper>
  );
};

export default DotText;
