import { useTranslation } from "react-i18next";
import { Text, Spacer } from "@lrnz/styled";
import styled from "styled-components";
import RevealStagger from "../components/RevealStagger";
import RevealElement from "../components/RevealElement";
import { useIntro } from "../context";
import Image from "../components/Image";
import MorphElement from "./MorphElement";
import { useLocation } from "react-router";
import { breakpoints } from "../containers/Homepage";
import { scale } from "../styles/keyframes";
import useThemeBreakpoint from "../hooks/useThemeBreakPoint";
import get from "@lrnz/dev-utils/dom/style-properties/get";
import clamp from "@lrnz/dev-utils/math/clamp";

const StyledWrapper = styled.div`
  position: fixed;
  inset: 0;
  z-index: ${(props) => props.theme.zIndex.loader};
  display: flex;
  align-items: flex-end;
  justify-content: center;
  text-align: center;
`;

const StyledWrapperImage = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  inset: 0;

  @media (min-aspect-ratio: 3/2) {
    flex-direction: column;
  }
`;

const StyledImage = styled.img`
  max-width: none;
  max-height: none;
  filter: saturate(0);
  animation: ${scale} 7.3s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;

  width: auto;
  height: 100%;

  @media (min-aspect-ratio: 3/2) {
    width: 100%;
    height: auto;
  }
`;

const StyledText = styled.div`
  mix-blend-mode: difference;
`;

const Loader = () => {
  const { t } = useTranslation();
  const intro = useIntro();
  const location = useLocation();
  const isMd = useThemeBreakpoint("md");

  return (
    <StyledWrapper>
      <StyledWrapperImage
        as={location.pathname === "/" ? MorphElement : RevealElement}
        boundsGenerator={() => {
          const { width } = breakpoints.find(
            (b) => global.matchMedia(b.media).matches
          );

          let padding = get({ name: "--gallery-item-padding-small" });
          padding = padding.match(/\d+/g);
          padding = clamp(
            padding[0],
            (window.innerWidth * padding[1]) / 100,
            padding[2]
          );

          const calculatedWidth = width - padding * 2; // substract padding x 2
          const calculatedHeight =
            (calculatedWidth * intro.height) / intro.width;

          return {
            top: (global.innerHeight - calculatedHeight) / 2,
            left: (global.innerWidth - calculatedWidth) / 2,
            width: calculatedWidth,
            height: calculatedHeight,
          };
        }}
        exit={location.pathname !== "/" ? "fade" : undefined}
        duration={2.2}
      >
        <StyledImage
          alt="loader image"
          as={Image}
          src={intro.src}
          params="?w=1000"
        />
      </StyledWrapperImage>
      <Spacer as={RevealElement} exit="fade" top="md" root={["lg", "sm"]}>
        <Text as={StyledText} variant="caption">
          <RevealElement enter="fadeUp" delay={3.4}>
            {t("meta.description")}
          </RevealElement>
        </Text>
        <Text variant="display1">
          <RevealStagger as="span" delay={1.4}>
            {t("meta.firstname")}
          </RevealStagger>
          {isMd && <br />}
          <i>
            <RevealStagger as="span" delay={2.3}>
              {t("meta.secondname")}
            </RevealStagger>
          </i>
        </Text>
      </Spacer>
    </StyledWrapper>
  );
};

export default Loader;
