export const IS_TOUCH = typeof window !== 'undefined' && 'ontouchstart' in global;

export const EVENTS = {
  START: IS_TOUCH ? 'touchstart' : 'mousedown',
  MOVE: IS_TOUCH ? 'touchmove' : 'mousemove',
  END: IS_TOUCH ? 'touchend' : 'mouseup',
  RESIZE: IS_TOUCH ? 'orientationchange' : 'resize',
  LEAVE: 'mouseleave',
};

export const NEAREST_POINTS = [
  [-1, -1],
  [-1, 0],
  [-1, 1],
  [0, -1],
  [0, 1],
  [1, -1],
  [1, 0],
  [1, 1],
];
