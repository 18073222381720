import styled from "styled-components";

const CircleIcon = styled.div`
  --radius: 30px;
  border-radius: 50%;
  background: white;
  width: calc(var(--radius) * 2);
  height: calc(var(--radius) * 2);
  display: flex;
  color: black;
  align-items: center;
  justify-content: center;
  border: 2px solid currentColor;

  path {
    stroke: currentColor;
    stroke-width: 2px;
    fill: none;
  }
`;

export default CircleIcon;
