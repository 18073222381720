import styled from "styled-components";
import { hover } from "@lrnz/styled";

export default styled.div`
  --border-size: 50px;
  --padding: ${(props) =>
    props.$small
      ? "var(--gallery-item-padding-small)"
      : "var(--gallery-item-padding)"};

  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--padding);
  backface-visibility: hidden;
  transform: translateZ(0);

  & > * {
    filter: saturate(0%);
    transition: all 0.4s ease;
    box-shadow: 0 0 0 0 white;
  }

  ${hover`
    & > * {
      filter: saturate(100%);
      transform: scale(1.1);
      box-shadow: 0 0 0 var(--border-size) white;
    }
  `}
`;
