import React from "react";
import { motion } from "framer-motion";
import easing from "../utils/easing";

export const presets = {
  fade: {
    initial: { opacity: 0 },
    animate: {
      opacity: 1,
      transition: {
        duration: 0.6,
        ease: "linear",
      },
    },
    exit: {
      opacity: 0,
      transition: {
        duration: 0.4,
        ease: "linear",
      },
    },
  },
  scale: {
    initial: { scale: 0 },
    animate: {
      scale: 1,
    },
    exit: { scale: 0 },
  },
  fadeUp: {
    initial: { opacity: 0, y: 30 },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1.4,
        ease: easing.easeOutQuint,
      },
    },
    exit: { opacity: 0 },
  },
  letter: {
    initial: {
      opacity: 0,
      y: "20%",
      display: "inline-block",
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1.4,
        ease: [0.22, 1, 0.36, 1],
      },
    },
    exit: { opacity: 0 },
  },
  clipX: {
    initial: {
      clipPath: "inset(0% 100% 0% 0%)",
    },
    animate: {
      clipPath: "inset(0% 0% 0% 0%)",
      transition: {
        duration: 2,
        ease: easing.easeInOutQuint,
      },
    },
    exit: {
      clipPath: "inset(0% 100% 0% 0%)",
      transition: {
        duration: 1.2,
        ease: easing.easeInOutQuint,
      },
    },
  },
  clipY: {
    initial: {
      clipPath: "inset(0% 0% 100% 0%)",
    },
    animate: {
      clipPath: "inset(0% 0% 0% 0%)",
      transition: {
        duration: 2,
        ease: easing.easeInOutQuint,
      },
    },
    exit: {
      clipPath: "inset(100% 0% 0% 0%)",
      transition: {
        duration: 1.2,
        ease: easing.easeInOutQuint,
      },
    },
  },
  clipYReverse: {
    initial: {
      clipPath: "inset(100% 0% 0% 0%)",
    },
    animate: {
      clipPath: "inset(0% 0% 0% 0%)",
      transition: {
        duration: 2,
        ease: easing.easeInOutQuint,
      },
    },
    exit: {
      clipPath: "inset(0% 0% 100% 0%)",
      transition: {
        duration: 1.2,
        ease: easing.easeInOutQuint,
      },
    },
  },
};

const RevealElement = ({
  children,
  exit,
  enter,
  delay,
  duration,
  exitDuration,
  exitDelay,
  as = "div",
  ...props
}) => {
  const transition = {};

  if (typeof duration === "number") {
    transition.duration = duration;
  }

  if (typeof delay === "number") {
    transition.delay = delay;
  }

  const exitTransition = {};

  if (typeof exitDuration === "number") {
    exitTransition.duration = exitDuration;
  }

  if (typeof exitDelay === "number") {
    exitTransition.delay = exitDelay;
  }

  const Component = motion[as];

  return (
    <Component
      initial={enter ? presets[enter].initial : undefined}
      animate={
        enter
          ? {
              ...presets[enter].animate,
              transition: {
                ...presets[enter].animate.transition,
                ...transition,
              },
            }
          : undefined
      }
      exit={
        exit
          ? {
              ...presets[exit].exit,
              transition: {
                ...presets[exit].exit.transition,
                ...exitTransition,
              },
            }
          : undefined
      }
      {...props}
    >
      {children}
    </Component>
  );
};

export default RevealElement;
