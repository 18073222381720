import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import it from "./locales/it.json";
import en from "./locales/en.json";

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    detection: {
      order: ["sessionStorage", "navigator", "localStorage", "htmlTag"],
    },
    resources: {
      en: {
        translation: en,
      },
      it: {
        translation: it,
      },
    },
  });

export default i18n;
