import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { breakpoints } from "@lrnz/styled";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import RevealElement from "./RevealElement";
import { useDataContext } from "../context";
import { AnimatePresence } from "framer-motion";
import { useResize } from "@lrnz/react-handlers";
import Menu from "./Menu";
import set from "@lrnz/dev-utils/dom/style-properties/set";
import useThemeBreakpoint from "../hooks/useThemeBreakPoint";
import { IS_TOUCH } from "../components/Grid/constants";
import StyledLink from "../components/Link";

const StyledItem = styled.div`
  position: fixed;
  left: ${(props) => props.$left && `${props.theme.space.lg}px`};
  right: ${(props) => props.$right && `${props.theme.space.lg}px`};
  bottom: ${(props) => props.$bottom && `${props.theme.space.lg}px`};
  top: ${(props) => props.$top && `${props.theme.space.lg}px`};
  z-index: ${(props) => props.theme.zIndex.interface};
  mix-blend-mode: difference;
  display: inline-flex;

  ${breakpoints("md")(
    (props) => `
    left: ${props.$left && `${props.theme.space.sm}px`};
    right: ${props.$right && `${props.theme.space.sm}px`};
    bottom: ${props.$bottom && `${props.theme.space.md}px`};
    top: ${props.$top && `${props.theme.space.md}px`};
  `
  )}
`;

const Interface = () => {
  const { t } = useTranslation();
  const { galleryType, isMenuOpen, openMenu, closeMenu } = useDataContext();
  const location = useLocation();
  const isSm = useThemeBreakpoint("sm");

  useResize(() =>
    set({
      name: "--vh",
      value: `${window.innerHeight * 0.01}px`,
    })
  );

  useEffect(() => {
    closeMenu();
    global.ga("send", "pageview");
  }, [closeMenu, location.pathname]);

  return (
    <AnimatePresence exitBeforeEnter={false}>
      {isMenuOpen && <Menu />}
      <StyledItem
        as={RevealElement}
        enter="clipX"
        delay={IS_TOUCH ? 0 : 2.3}
        $top
        $left
        key={0}
      >
        <StyledLink
          as={Link}
          variant="interface"
          to="/"
          $isActive={location.pathname === "/"}
        >
          {t("meta.firstname")}&nbsp;
          <i>{t("meta.secondname")}</i>
        </StyledLink>
      </StyledItem>
      {!galleryType && !isSm && (
        <StyledItem
          as={RevealElement}
          enter="clipX"
          exit="fade"
          delay={2.3}
          $top
          $right
          key={1}
        >
          <StyledLink
            as={Link}
            to={`/creations`}
            variant="interface"
            $isActive={location.pathname === "/creations"}
          >
            <i>{t("common.creations")}</i>
          </StyledLink>
        </StyledItem>
      )}
      {!galleryType && !isSm && (
        <StyledItem
          as={RevealElement}
          $bottom
          $left
          exit="fade"
          enter="clipX"
          delay={2.9}
          key={2}
        >
          <StyledLink
            as={Link}
            variant="interface"
            to="/interview"
            $isActive={location.pathname === "/interview"}
          >
            <i>{t("common.interview")}</i>
          </StyledLink>
        </StyledItem>
      )}
      {!galleryType && !isSm && (
        <StyledItem
          as={RevealElement}
          $bottom
          $right
          exit="fade"
          enter="clipX"
          delay={3.2}
          key={3}
        >
          <StyledLink
            as={Link}
            variant="interface"
            to="/contacts"
            $isActive={location.pathname === "/contacts"}
          >
            {t("common.contacts")}
          </StyledLink>
        </StyledItem>
      )}
      {galleryType && (
        <StyledItem
          $top
          $right
          exit="fade"
          enter="clipX"
          delay={0.6}
          as={RevealElement}
          key={4}
        >
          <StyledLink as={Link} to={`/${galleryType}`} variant="interface">
            <i>{t("common.close")}</i>
          </StyledLink>
        </StyledItem>
      )}
      {isSm && !isMenuOpen && !galleryType && (
        <StyledItem
          as={RevealElement}
          enter="clipX"
          exit="fade"
          $top
          $right
          key={5}
        >
          <StyledLink onClick={openMenu} variant="interface">
            <i>{t("common.menu")}</i>
          </StyledLink>
        </StyledItem>
      )}
      {isSm && isMenuOpen && !galleryType && (
        <StyledItem
          $top
          $right
          exit="fade"
          enter="clipX"
          as={RevealElement}
          key={6}
        >
          <StyledLink onClick={closeMenu} variant="interface">
            <i>{t("common.close")}</i>
          </StyledLink>
        </StyledItem>
      )}
    </AnimatePresence>
  );
};

export default Interface;
