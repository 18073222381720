import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import Grid from "../components/Grid";
import WrapperGrid from "../components/WrapperGrid";
import Image from "../components/Image";
import GridItem from "../components/GridItem";
import {
  useCreations,
  useDataContext,
  useInterviews,
  useIntro,
} from "../context";
import { IS_TOUCH } from "../components/Grid/constants";
import { getGalleryBounds } from "../utils";
import MorphElement from "../components/MorphElement";
import RevealElement from "../components/RevealElement";
import { useIsPresent } from "framer-motion";

export const breakpoints = [
  {
    media: "(max-width: 768px)",
    width: 220,
    height: 250,
  },
  {
    media: "(max-width: 1000px)",
    width: 450,
    height: 450,
  },
  {
    media: "(max-width: 1400px)",
    width: 550,
    height: 550,
  },
  {
    media: "(min-width: 1400px)",
    width: 740,
    height: 740,
  },
];

const Homepage = () => {
  const isPresence = useIsPresent();
  const interview = useInterviews();
  const creations = useCreations();
  const intro = useIntro();
  const gridRef = useRef();
  const items = [intro, ...interview, ...creations];
  const [clickedSrc, setClickedSrc] = useState();
  const { setFromHomepage } = useDataContext();

  const onItemClick = (route) => (e) => {
    e.currentTarget.style.opacity = 0;
    const $img = e.currentTarget.querySelector("img");
    const bounds = $img.getBoundingClientRect();

    setClickedSrc({
      src: route.src,
      bounds,
    });
    setFromHomepage(true);
  };

  return (
    <>
      <RevealElement exit="fade" enter="fade">
        <WrapperGrid>
          <Grid
            ref={gridRef}
            activeMouse={!IS_TOUCH && isPresence}
            activeDrag={IS_TOUCH && isPresence}
            breakpoints={breakpoints}
          >
            {items.map((item) => (
              <GridItem
                as={Link}
                key={item.src}
                onClick={onItemClick(item)}
                $small={item.width > item.height}
                to={{
                  pathname: "/gallery",
                  state: { src: item.src },
                }}
              >
                <Image
                  src={item.src}
                  height={item.height > item.width ? item.height : null}
                />
              </GridItem>
            ))}
          </Grid>
        </WrapperGrid>
      </RevealElement>
      {clickedSrc && (
        <div
          style={{
            position: "fixed",
            top: clickedSrc.bounds.top,
            left: clickedSrc.bounds.left,
            width: clickedSrc.bounds.width,
            height: clickedSrc.bounds.height,
          }}
        >
          <MorphElement
            initial={{
              position: "absolute",
              inset: IS_TOUCH ? 0 : -55,
              background: "white",
            }}
            boundsGenerator={() => ({
              top: 0,
              left: 0,
              width: global.innerWidth,
              height: global.innerHeight,
            })}
            duration={1}
          />
          <MorphElement boundsGenerator={getGalleryBounds} duration={1.4}>
            <Image
              style={{ objectFit: "contain", width: "100%", height: "100%" }}
              src={clickedSrc.src}
            />
          </MorphElement>
        </div>
      )}
    </>
  );
};

export default Homepage;
