import { createContext, useCallback, useContext, useState } from "react";
import { getInterview, getCreations, getIntro, getContacts } from "./selector";
import useFetch from "fetch-suspense";
const Context = createContext();

export const Provider = ({ children }) => {
  const [cursorText, setCursorText] = useState();
  const [fromHomepage, setFromHomepage] = useState();
  const [galleryType, setGalleryType] = useState();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const images = useFetch(process.env.REACT_APP_IMAGE_JSON_PATH);

  const closeMenu = useCallback(() => setIsMenuOpen(false), []);
  const openMenu = useCallback(() => setIsMenuOpen(true), []);

  return (
    <Context.Provider
      value={{
        cursorText,
        fromHomepage,
        galleryType,
        images,
        isMenuOpen,
        setCursorText,
        setFromHomepage,
        setGalleryType,
        closeMenu,
        openMenu,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useDataContext = () => useContext(Context);

export const useInterviews = () => getInterview(useDataContext(Context));

export const useCreations = () => getCreations(useContext(Context));

export const useIntro = () => getIntro(useContext(Context));

export const useContacts = () => getContacts(useContext(Context));
