import { useTranslation } from "react-i18next";
import { Spacer, Text } from "@lrnz/styled";
import HoverElement from "../components/HoverElement";
import Image from "../components/Image";
import RevealElement, { presets } from "../components/RevealElement";
import RevealStagger from "../components/RevealStagger";
import { useContacts } from "../context";
import Scrollbar from "react-smooth-scrollbar";
import { motion } from "framer-motion";
import useThemeBreakpoint from "../hooks/useThemeBreakPoint";

const Contacts = () => {
  const { t } = useTranslation();
  const [contacts1, contacts2, contacts3, contacts4, contacts5] = useContacts();

  const isSm = useThemeBreakpoint("sm");

  return (
    <Scrollbar>
      <Text align="center" as={motion.div} exit={presets.fade.exit}>
        <Spacer root={["xxl", "md", "md", "md"]} top="xl">
          <HoverElement
            element={<Image src={contacts1.src} params={"?w=500&sat=-100"} />}
          >
            <Text variant="display1">
              <RevealStagger as="span">{t("meta.firstname")}</RevealStagger>
              {isSm && <br />}
              <i>
                <RevealStagger as="span" delay={0.5}>
                  {t("meta.secondname")}
                </RevealStagger>
              </i>
            </Text>
          </HoverElement>
          <RevealElement enter="fadeUp" delay={0.8}>
            <Spacer top="xl">
              <HoverElement
                element={
                  <Image src={contacts2.src} params={"?w=330&sat=-100"} />
                }
              >
                <Text
                  variant="display4"
                  className="remove-br"
                  dangerouslySetInnerHTML={{
                    __html: t("pages.contact.description"),
                  }}
                />
              </HoverElement>
              <Spacer root={{ top: "xxl" }} sm={{ root: { top: "lg" } }}>
                <HoverElement
                  element={
                    <Image src={contacts3.src} params={"?w=480&sat=-100"} />
                  }
                >
                  <Spacer top="sm">
                    <Text variant="caption">{t("common.workshop")}</Text>
                    <Text
                      as="a"
                      className="remove-br"
                      target="_blank"
                      rel="noreferrer"
                      href={t("contacts.googleMaps")}
                      variant="display3"
                      dangerouslySetInnerHTML={{
                        __html: t("contacts.address"),
                      }}
                    />
                  </Spacer>
                </HoverElement>
              </Spacer>
              <HoverElement
                element={<Image src={contacts4.src} params="?w=350&sat=-100" />}
              >
                <Spacer top="sm">
                  <Text variant="caption">{t("common.contacts")}</Text>
                  <Text variant="display3">
                    <i>{t("common.phone")}</i> . {isSm && <br />}
                    <a href={`tel:${t("contacts.phone")}`}>
                      {t("contacts.phone")}
                    </a>
                    <br />
                    <i>{t("common.mail")}</i> .{" "}
                    <a href={`mailto:${t("contacts.mail").toLowerCase()}`}>
                      {t("contacts.mail")}
                    </a>
                  </Text>
                </Spacer>
              </HoverElement>
              <br />
              <HoverElement
                element={<Image src={contacts5.src} params="?w=350&sat=-100" />}
              >
                <Spacer top="sm">
                  <Text variant="caption">{t("common.social")}</Text>
                  <Text variant="display3">
                    <i>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={t("contacts.facebook")}
                      >
                        {t("common.facebook")}
                      </a>{" "}
                      .{" "}
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={t("contacts.instagram")}
                      >
                        {t("common.instagram")}
                      </a>
                    </i>
                  </Text>
                </Spacer>
              </HoverElement>

              <Text variant="caption">{t("contacts.p-iva")}</Text>
            </Spacer>
          </RevealElement>
        </Spacer>
      </Text>
    </Scrollbar>
  );
};

export default Contacts;
