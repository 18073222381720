import React, { useEffect, useRef } from "react";
import { usePresence, useAnimation, motion } from "framer-motion";
import easings from "../utils/easing";

const MorphElement = ({ boundsGenerator, children, duration, ...props }) => {
  const $elRef = useRef();
  const [isPresent, safeToRemove] = usePresence();
  const controls = useAnimation();

  useEffect(() => {
    if (!isPresent) {
      const bounds = $elRef.current.getBoundingClientRect();
      const destinationBounds = boundsGenerator($elRef.current);

      controls.start({
        x: destinationBounds.left - bounds.left,
        y: destinationBounds.top - bounds.top,
        width: destinationBounds.width,
        height: destinationBounds.height,
      });
    }
  }, [boundsGenerator, controls, isPresent]);

  return (
    <motion.div
      animate={controls}
      ref={$elRef}
      onAnimationComplete={safeToRemove}
      transition={{
        duration,
        ease: easings.easeInOutExpo,
      }}
      {...props}
    >
      {children}
    </motion.div>
  );
};

export default MorphElement;
