import React, {
  createRef,
  forwardRef,
  useImperativeHandle,
  useCallback,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import useAngle from "./use-angle";
import useMouse from "./use-mouse";
import useDrag from "./use-drag";
import useResize from "./use-resize";
import styles from "./index.module.css";
import { NEAREST_POINTS } from "./constants";
import { getRandomString, lerp, closest } from "./utils";
import useIsomorphicLayoutEffect from "./use-isomorphic-layout-effect";

const Grid = forwardRef(
  (
    {
      width,
      height,
      children,
      debug,
      breakpoints,
      onReady,
      activeDrag,
      activeMouse,
    },
    ref
  ) => {
    const dragRef = useRef({ x: 0, y: 0 });
    const pointerDragAngleRef = useRef(0);
    const velocityRef = useRef(0);
    const [cell, setCell] = useState({});
    const [bounds, setBounds] = useState({});
    const $wrapperRef = useRef();
    const $$itemsRef = useRef({});
    const itemsRef = useRef([]);
    const counterRef = useRef(0);
    const [items, setItems] = useState([]);
    const childrenLength = React.Children.count(children);

    itemsRef.current = items;

    const stop = useCallback(() => {
      velocityRef.current = 0;
    }, []);

    const pointIsVisible = useCallback(
      (point) => {
        const tollerance = {
          x: Math.max(0, cell.width - bounds.width),
          y: Math.max(0, cell.height - bounds.height),
        };
        return (
          point.x >= 0 - tollerance.x &&
          point.x <= bounds.width + tollerance.x &&
          point.y >= 0 - tollerance.y &&
          point.y <= bounds.height + tollerance.y
        );
      },
      [cell.width, cell.height, bounds.width, bounds.height]
    );

    const getNearestItems = useCallback(
      ({ row, col }) =>
        itemsRef.current.filter(
          (item) =>
            Math.abs(item.row - row) < 2 &&
            Math.abs(item.col - col) < 2 &&
            row !== item.row &&
            col !== item.col
        ),
      []
    );

    const generatePoint = useCallback(
      (row, col, offsetX = 0, offsetY = 0, isCenterPoint, rows, columns) => {
        const id = getRandomString(5);
        const x = cell.width * col + offsetX;
        let y = cell.height * row + offsetY;

        const halfColumn = Math.floor(columns / 2);

        if (Math.abs(halfColumn - col) % 2 > 0) {
          y += cell.height * 0.5;
        }

        const nearestItems = getNearestItems({ row, col });
        const nearestPoint = nearestItems[0];

        /* Subtract lerp compensation */
        const diffX = nearestPoint
          ? nearestPoint.x - (nearestPoint.initial.x + dragRef.current.x)
          : 0;
        const diffY = nearestPoint
          ? nearestPoint.y - (nearestPoint.initial.y + dragRef.current.y)
          : 0;

        /* Randomize elements */
        let childrenIndex = isCenterPoint
          ? 0
          : Math.abs(3 + row * 10 + col) % childrenLength;

        const point = {
          id,
          row,
          col,
          lerp: {
            x: 0.05 + 0.03 * Math.random(),
            y: 0.05 + 0.03 * Math.random(),
          },
          initial: {
            x,
            y,
          },
          x: x + dragRef.current.x + diffX,
          y: y + dragRef.current.y + diffY,
          childrenIndex,
          isCenterPoint,
        };

        return point;
      },
      [cell.width, cell.height, getNearestItems, childrenLength]
    );

    const relayout = useCallback(() => {
      const breakpoint = breakpoints.find(
        (b) => !b.media || global.matchMedia(b.media).matches
      );
      const wrapperBounds = $wrapperRef.current.getBoundingClientRect();

      setBounds({
        width: wrapperBounds.width,
        height: wrapperBounds.height,
      });
      setCell({
        width: breakpoint?.width || width,
        height: breakpoint?.height || height,
      });
    }, [breakpoints, width, height]);

    const isDragActive = useDrag(
      $wrapperRef,
      (delta) => {
        if (activeDrag) {
          dragRef.current.x -= delta.x;
          dragRef.current.y -= delta.y;
        }
      },
      [activeDrag],
      {
        preventDefault: true,
        threshold: 0,
      }
    );

    useAngle(
      $wrapperRef,
      (angle) => {
        if (activeDrag) {
          pointerDragAngleRef.current = angle;
        }
      },
      [activeDrag]
    );

    useMouse(
      $wrapperRef,
      (angle, distanceFromCenter) => {
        if (activeMouse) {
          pointerDragAngleRef.current = angle;
          velocityRef.current = -(distanceFromCenter * distanceFromCenter * 20);
        }
      },
      [activeMouse]
    );

    useResize($wrapperRef, relayout, [relayout]);

    useIsomorphicLayoutEffect(() => {
      let raf;
      let matrix;
      let prevPoints = 0;
      let rows;
      let columns;
      let centerCompensation = { x: 0, y: 0 };

      const getMatrix = () => {
        dragRef.current.x = 0;
        dragRef.current.y = 0;

        if (bounds.width && cell.width && childrenLength) {
          const m = {};
          rows = Math.ceil(bounds.height / cell.height);
          columns = Math.ceil(bounds.width / cell.width);

          const nearestX = closest(
            [...Array(columns).keys()].map((c) => c * cell.width),
            bounds.width / 2
          );
          const nearestY = closest(
            [...Array(rows).keys()].map((c) => c * cell.height),
            bounds.height / 2
          );
          centerCompensation.x = bounds.width / 2 - nearestX;
          centerCompensation.y = bounds.height / 2 - nearestY;

          [...Array(rows).keys()].forEach((r) => {
            const row = {};
            [...Array(columns).keys()].forEach((c) => {
              row[c] = generatePoint(
                r,
                c,
                centerCompensation.x,
                centerCompensation.y,
                r * cell.height === nearestY && c * cell.width === nearestX,
                rows,
                columns
              );
            });
            m[r] = row;
          });

          return m;
        }
        return null;
      };

      const tick = () => {
        let newPoints = 0;
        const t = [];
        const points = [];

        Object.keys(matrix)
          .map(Number)
          .forEach((row) => {
            Object.keys(matrix[row])
              .map(Number)
              .forEach((col) => {
                t.push([row, col]);
              });
          });

        t.forEach(([row, col]) => {
          const point = matrix[row][col];

          if (pointIsVisible(point)) {
            /** If point is visible,
             * generate nearest point
             * */
            NEAREST_POINTS.forEach((near) => {
              const calculatedR = row + near[0];
              const calculatedC = col + near[1];
              if (!matrix[calculatedR]) {
                matrix[calculatedR] = {};
              }
              if (!matrix[calculatedR][calculatedC]) {
                matrix[calculatedR][calculatedC] = generatePoint(
                  calculatedR,
                  calculatedC,
                  centerCompensation.x,
                  centerCompensation.y,
                  false,
                  rows,
                  columns
                );
              }
            });
          } else {
            /**
             * If point isn't visible,
             * delete it if all its nearest points are not visible
             * */
            let q = false;
            NEAREST_POINTS.forEach((near) => {
              const calculatedR = row + near[0];
              const calculatedC = col + near[1];
              const p = matrix[calculatedR];
              if (p) {
                const b = p[calculatedC];
                if (b) {
                  if (!q) {
                    q = pointIsVisible(b);
                  }
                }
              }
            });

            if (!q) {
              delete matrix[row][col];
            }
          }

          /** Delete the row if it's empty */
          if (Object.keys(matrix[row]).length === 0) {
            delete matrix[row];
          }

          const $point = $$itemsRef.current[point.id]?.current;

          if (!$point) {
            $$itemsRef.current[point.id] = createRef();
          }
          points.push(point);

          newPoints += point.row + point.col;
        });

        dragRef.current.x +=
          Math.cos(pointerDragAngleRef.current) * velocityRef.current;
        dragRef.current.y +=
          Math.sin(pointerDragAngleRef.current) * velocityRef.current;

        if (prevPoints !== newPoints) {
          setItems(points);
        }

        prevPoints = newPoints;

        raf = global.requestAnimationFrame(tick);

        /* Recreate the matrix if for
      /* reconciliation performance reasons
      /* grid remains empty
      */
        if (points.length === 0) {
          matrix = getMatrix();
        }
      };

      matrix = getMatrix();

      if (matrix) {
        tick();
      }

      return () => {
        global.cancelAnimationFrame(raf);
      };
    }, [
      generatePoint,
      pointIsVisible,
      bounds.width,
      bounds.height,
      debug,
      cell.width,
      cell.height,
      childrenLength,
    ]);

    useIsomorphicLayoutEffect(() => {
      let raf;

      const tick = () => {
        itemsRef.current.forEach((point) => {
          const $point = $$itemsRef.current[point.id]?.current;
          if ($point) {
            const itemWidth = debug ? 20 : cell.width;
            const itemHeight = debug ? 20 : cell.height;
            /* eslint-disable no-param-reassign */
            point.x = lerp(
              point.x,
              point.initial.x + dragRef.current.x,
              point.lerp.x
            );
            point.y = lerp(
              point.y,
              point.initial.y + dragRef.current.y,
              point.lerp.y
            );
            /* eslint-enable no-param-reassign */
            let transform = `translateX(${point.x}px)`;
            transform += `translateY(${point.y}px)`;
            transform += "translateX(-50%)";
            transform += "translateY(-50%)";
            $point.style.setProperty("transform", transform);
            $point.style.setProperty("width", `${itemWidth}px`);
            $point.style.setProperty("height", `${itemHeight}px`);
          }
        });
        raf = global.requestAnimationFrame(tick);
      };

      tick();

      if (counterRef.current === 3) {
        if (typeof onReady === "function") {
          onReady();
        }
      }

      counterRef.current += 1;

      return () => {
        global.cancelAnimationFrame(raf);
      };
    }, [cell.height, cell.width, debug, onReady]);

    useImperativeHandle(ref, () => ({
      stop,
      isDragActive: () => isDragActive,
      getWrapper: () => $wrapperRef.current,
      getItems: () => $$itemsRef.current,
      getPointerDragAngle: () => pointerDragAngleRef.current,
    }));

    return (
      <div
        ref={$wrapperRef}
        className={`${styles.wrapper} ${debug ? styles.debug : ""} ${
          activeDrag ? styles.activeDrag : ""
        }`}
      >
        {childrenLength
          ? items.map((p) => (
              <div
                className={`${styles.item}`}
                data-center={p.isCenterPoint}
                ref={$$itemsRef.current[p.id]}
                key={p.id}
              >
                {debug ? p.childrenIndex : children[p.childrenIndex]}
              </div>
            ))
          : null}
      </div>
    );
  }
);

Grid.defaultProps = {
  debug: false,
  breakpoints: [],
  children: null,
  onReady: null,
  activeDrag: true,
  activeMouse: false,
};

Grid.propTypes = {
  debug: PropTypes.bool,
  breakpoints: PropTypes.arrayOf(
    PropTypes.shape({
      media: PropTypes.string,
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
    })
  ),
  onReady: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  activeDrag: PropTypes.bool,
  activeMouse: PropTypes.bool,
};

Grid.displayName = "Grid";

export default Grid;
