import { useEffect, useState, useRef } from "react";
import RevealElement from "../components/RevealElement";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Image from "../components/Image";
import DotText from "../components/DotText";
import Wrapper from "../components/Wrapper";
import { getGalleryBounds } from "../utils";
import MorphElement from "../components/MorphElement";
import DoubleScrollbar from "../components/DoubleScrollbar";
import RevealStagger from "../components/RevealStagger";
import { Row, Col, Spacer, Text } from "@lrnz/styled";
import { useCreations, useInterviews } from "../context";
import { useIsPresent } from "framer-motion";
import { useDataContext } from "../context";
import useThemeBreakpoint from "../hooks/useThemeBreakPoint";

const Detail = (props) => {
  const { t } = useTranslation();
  const doubleScrollbarRef = useRef();
  const interview = useInterviews();
  const creations = useCreations();
  const isPresence = useIsPresent();

  const { template } = props.match.params;

  const isCreations = template === "creations";
  const isMd = useThemeBreakpoint("md");

  const [clickedSrc, setClickedSrc] = useState();
  const { setFromHomepage } = useDataContext();

  const onItemClick = (route) => (e) => {
    const $img = e.currentTarget.querySelector("img");
    const bounds = $img.getBoundingClientRect();

    if (!isMd) {
      document.documentElement.style.setProperty("--gallery-padding", "0px");
    }

    setClickedSrc({
      src: route.src,
      bounds,
    });
    setFromHomepage(true);
  };

  useEffect(() => {
    doubleScrollbarRef.current.resetScrollbars();
  }, [template]);

  return (
    <>
      <DoubleScrollbar ref={doubleScrollbarRef} reverse={!isMd && isCreations}>
        <RevealElement
          enter={!isCreations ? "clipX" : undefined}
          exit={!isCreations ? "clipX" : "fade"}
          style={{ zIndex: !isPresence ? -1 : null }}
        >
          <Spacer
            as={Wrapper}
            negative={isCreations}
            root={
              !isCreations
                ? ["xxl", "xl", "xxxl", "detail"]
                : ["xxl", "detail", "xxxl", "xl"]
            }
            top="xxxxl"
            md={{
              top: "xxl",
              root: ["xxxl", "sm", "lg", "sm"],
            }}
          >
            <Text
              align={!isCreations && !isMd ? "right" : null}
              variant="display2"
              style={{ whiteSpace: "nowrap" }}
            >
              <i>
                <RevealStagger delay={0.6}>
                  {t(`common.${template}`)}
                </RevealStagger>
              </i>
            </Text>
            <RevealElement enter="fadeUp" delay={1.2}>
              <Spacer top="xl" md={{ top: "lg" }}>
                {t(`pages.${template}`, { returnObjects: true }).map(
                  ({ label, description }, index) => (
                    <DotText key={description} label={label}>
                      {description}
                    </DotText>
                  )
                )}
              </Spacer>
            </RevealElement>
          </Spacer>
        </RevealElement>
        <RevealElement
          enter={isCreations ? "clipX" : undefined}
          exit={!isCreations ? "fade" : "clipX"}
        >
          <Wrapper
            negative={!isCreations}
            as={Spacer}
            root={isMd ? "xs" : "lg"}
          >
            <RevealElement delay={!isCreations ? 0.6 : 0.3} enter="clipX">
              <Spacer top={isMd ? "md" : "lg"}>
                {(!isCreations ? interview : creations).map(
                  ({ src, width, height }) => (
                    <Spacer
                      key={src}
                      root={{
                        left: height > width ? (isMd ? "md" : "lg") : null,
                        right: height > width ? (isMd ? "md" : "lg") : null,
                      }}
                      style={{
                        opacity: src === clickedSrc?.src ? 0 : null,
                      }}
                    >
                      <Link
                        to={{
                          pathname: `/gallery/${template}`,
                          state: { src: src },
                        }}
                        onClick={onItemClick({ src })}
                      >
                        <Image
                          saturateOnHover
                          ratio={Math.round((width / height) * 100) / 100}
                          src={src}
                          fullWidth
                        />
                      </Link>
                    </Spacer>
                  )
                )}
              </Spacer>
            </RevealElement>
          </Wrapper>
        </RevealElement>
      </DoubleScrollbar>
      {clickedSrc && (
        <>
          <Row
            style={{
              position: "fixed",
              inset: 0,
              zIndex: isMd ? 0 : -1,
            }}
          >
            <Col col={isMd ? 24 : !isCreations ? 14 : 10}>
              <MorphElement
                boundsGenerator={() => ({
                  top: 0,
                  left: 0,
                  width: global.innerWidth,
                  height: global.innerHeight,
                })}
                duration={1}
                style={{
                  width: isMd ? 0 : null,
                  height: "100%",
                  background: "white",
                }}
              />
            </Col>
          </Row>
          <MorphElement
            boundsGenerator={getGalleryBounds}
            duration={1.4}
            style={{
              position: "fixed",
              top: clickedSrc.bounds.top,
              left: clickedSrc.bounds.left,
              width: clickedSrc.bounds.width,
              height: clickedSrc.bounds.height,
            }}
          >
            <Image style={{ width: "100%" }} src={clickedSrc.src} />
          </MorphElement>
        </>
      )}
    </>
  );
};

export default Detail;
