import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Spacer, Text } from "@lrnz/styled";
import RevealElement from "./RevealElement";
import { useDataContext } from "../context";

const StyledMenu = styled.div`
  position: fixed;
  inset: 0;
  background: black;
  z-index: ${(props) => props.theme.zIndex.menu};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const StyledItem = styled.div`
  display: inline-flex;
`;

const Menu = () => {
  const { t } = useTranslation();

  const { closeMenu } = useDataContext();

  return (
    <StyledMenu as={RevealElement} enter="clipY" exit="clipYReverse">
      <Spacer top="sm" root={["lg", "sm", "md", "sm"]}>
        <StyledItem as={Link} to="/" onClick={closeMenu}>
          <Text to="/" variant="display2">
            {t("common.home")}
          </Text>
        </StyledItem>
        <br />
        <StyledItem as={Link} to="/interview" onClick={closeMenu}>
          <Text variant="display2">
            <i>{t("common.interview")}</i>
          </Text>
        </StyledItem>
        <br />
        <StyledItem as={Link} to="/creations" onClick={closeMenu}>
          <Text variant="display2">{t("common.creations")}</Text>
        </StyledItem>
        <br />
        <StyledItem as={Link} to="/contacts" onClick={closeMenu}>
          <Text variant="display2">
            <i>{t("common.contacts")}</i>
          </Text>
        </StyledItem>
      </Spacer>
    </StyledMenu>
  );
};

export default Menu;
