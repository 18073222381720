import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

const AnimatedRoutes = ({ children }) => (
  <Route render={({ location }) => (
      <AnimatePresence exitBeforeEnter>
        <Switch
          location={location}
          key={location.pathname}
        >
          {children}
        </Switch>
      </AnimatePresence>
    )}
  />
);

export default AnimatedRoutes;
