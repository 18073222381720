import get from "@lrnz/dev-utils/dom/style-properties/get";
import backgroundSize from "@lrnz/dev-utils/math/background-size";
import { padding as paddingFn } from "polished";

export const getGalleryBounds = ($img) => {
  const matches = get({ name: "--gallery-padding" }).match(/\d+/g);

  const { paddingTop, paddingLeft, paddingRight, paddingBottom } = paddingFn(
    ...matches
  );

  const bounds = $img.getBoundingClientRect();

  const { width, height } = backgroundSize(
    [
      window.innerWidth - paddingLeft - paddingRight,
      window.innerHeight - paddingTop - paddingBottom,
    ],
    [bounds.width, bounds.height],
    "contain"
  );

  const top =
    global.innerHeight / 2 - height / 2 + (paddingTop - paddingBottom) / 2;
  const left = global.innerWidth / 2 - width / 2;

  return {
    top,
    left,
    width,
    height,
  };
};
